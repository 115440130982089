import React, { useState } from "react";
import styles from "./Text.module.css";
export default function Text({ string1, string2, link1, link2 }: TextProps) {
  const [isActive, setIsActive] = useState<boolean>(false);
  const handleOnClick = () => {
    setIsActive(!isActive);
  };
  return (
    <div className={styles.footer}>
      <div className={styles.wrap} onClick={handleOnClick}>
        <span className={styles.arrow}>
          <span
            className={isActive ? styles.leftLine : styles.leftLineActive}
          ></span>
          <span
            className={isActive ? styles.rightLine : styles.rightLineActive}
          ></span>
        </span>
      </div>
      <div className={isActive ? styles.descriptionActive : styles.description}>
        <p>
          {string1}
          <a href={link1} target="_blank">
            Hier gehts zur Konsumentenstudie.
          </a>
        </p>
        <p>
          {string2}
          <a href={link2} target="_blank">
            Hier gehts zur Werberat-Website.
          </a>
        </p>
      </div>
    </div>
  );
}
interface TextProps {
  string1: string;
  string2: string;
  link1: string;
  link2: string;
}
