import React from "react";

import styles from "./header.module.css";
export default function Header() {
  return (
    <header className={styles.header}>
      <img src={"img/logo.png"} />

      <div className={styles.mainText}>
        <div>
          <p>Werbung bewegt.</p>
          <p>Werbung fällt auf.</p>
          <p>Werbung wird diskutiert.</p>
        </div>
      </div>
    </header>
  );
}
