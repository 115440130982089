import React, { useState } from "react";

import "./App.css";
import Grid from "./components/Grid";
import Header from "./components/header";
import ImageSlider from "./components/imageSlider";
import Modal from "./components/Modal";
import Text from "./components/Text";
import useWindowDimensions from "./helper/windowWithHook";
//for the main page
const mpparagraph1 =
  "Der Reigen von Eigenschaften die Werbung zugeschrieben werden reicht von informativ und interessant, über unterhaltsam und notwendig bis hin zu beeinflussend, übertrieben und störend. Eigenschaften, die so vielfältig sind wie die Österreichische Werbelandschaft. Gleichzeitig wird Werbung als wichtiger Bestandteil der Wirtschaft wahrgenommen und als wichtige Orientierungshilfe verstanden. ";
const mpparagraph2 =
  " Umso wichtiger sind hier ethische und moralische Grundlagen, zu denen sich die Kommunikationsbranche selbst bekennt und entsprechend in ihrer Kommunikation für den Kunden berücksichtigt. Für die Einhaltung und viel mehr noch für die Weiterentwicklung dieser Regeln ist der Österreichische Werberat zuständig. ";
const mplink1 = "https://werberat.at/news_251.aspx";
const mplink2 = "https://werberat.at/show_86.aspx";

function App() {
  const [imageNumber, setImageNumber] = useState<number>(0);
  const { height, width } = useWindowDimensions();
  return (
    <div className="App">
      {imageNumber === 0 ? (
        <>
          <Header></Header>
          {width > 700 ? (
            <Grid setImageNumber={setImageNumber}></Grid>
          ) : (
            <ImageSlider setImageNumber={setImageNumber}></ImageSlider>
          )}
          <Text
            string1={mpparagraph1}
            string2={mpparagraph2}
            link1={mplink1}
            link2={mplink2}
          ></Text>
        </>
      ) : (
        <Modal
          imageNumber={imageNumber}
          setImageNumber={setImageNumber}
        ></Modal>
      )}
    </div>
  );
}

export default App;
