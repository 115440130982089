export const data = [
  {
    id: 1,
    img: "img/image1.png",
    link: "https://werberat-n-staging.azurewebsites.net/SujetEthik.aspx",
  },
  {
    id: 2,
    img: "img/image2.png",
    link: "https://werberat-n-staging.azurewebsites.net/SujetSexistischeWerbung.aspx",
  },
  {
    id: 3,
    img: "img/image3.png",
    link: "https://werberat-n-staging.azurewebsites.net/SujetMarken.aspx",
  },
  {
    id: 4,
    img: "img/image4.png",
    link: "https://werberat-n-staging.azurewebsites.net/SujetWirtschaftsfaktorWerbung.aspx",
  },
  {
    id: 5,
    img: "img/image5.png",
    link: "https://werberat-n-staging.azurewebsites.net/SujetDiskriminierung.aspx",
  },
];
