import React from "react";
import styles from "./Modal.module.css";
import { IoMdClose } from "react-icons/io";
import { RiExternalLinkLine } from "react-icons/ri";
import { IconContext } from "react-icons";
import { CgWebsite } from "react-icons/cg";
import Text from "./Text";
import { data } from "../helper/data";
//for the popup
const popparagraph1 =
  "Wirtschaftsfaktor Werbung Das Bewusstsein über die Bedeutung von Werbung für die Wirtschaft hat signifikant zugenommen hat. Entsprechend selbstverständlich ist es, dass für Produkte, die legal verkauft werden dürfen, auch Werbung gemacht wird. Auch die absatzfördernde Leistung, der Garant für Arbeitsplätze oder das Verständnis über die Wirkungsweise von Werbung (keine kostenlosen Informationen ohne Werbung) ist dem mündigen Konsumenten im 21. Jahrhundert vertraut. ";
const popparagraph2 =
  "Nutzen von Werbung Bereits 2015 wurde Werbung als wichtige Orientierungshilfe im Reigen der Angebotsvielfalt verstanden. Noch deutlicher fällt das Ergebnis 2018 aus. So gaben 87 % der Befragten (vgl. 2015: 84 %) an, dass sie durch Werbung auf neue Produkte aufmerksam geworden sind und 82 % (vgl. 2015: 77 %) erkennen den konkreten Nutzen von Werbung für sich selbst: „Durch Werbung kann ich mir Geld sparen“. Ist Werbung dann auch noch gut gemacht, gilt es als regelrechtes Vergnügen sie zu sehen. Hier gehts zum Pressearchiv. ";
const poplink1 = "https://www.werberat.at/";
const poplink2 = "https://www.werberat.at/";

export default function Modal({ imageNumber, setImageNumber }: ModalProps) {
  console.log(imageNumber);
  if (imageNumber === 0) return null;
  const closePopup = () => {
    setImageNumber(0);
  };
  const rightItem = data.find((item) => item.id === imageNumber);

  return (
    <div>
      <div className={styles.overlay}>
        <div className={styles.innerStyle}>
          <div className={styles.container}>
            <IconContext.Provider
              value={{
                size: "2.5em",
                color: "#4b596b",
              }}
            >
              <div className={styles.closeIcon}>
                <IoMdClose onClick={closePopup} />
              </div>
            </IconContext.Provider>

            <img src={rightItem?.img}></img>
            <div className={styles.buttoncontainer}>
              <a className={styles.btn} href={rightItem?.link} target="_blank">
                <span>Details</span>
                <IconContext.Provider
                  value={{
                    size: "2.5em",
                    color: "#4b596b",
                  }}
                >
                  <RiExternalLinkLine />
                </IconContext.Provider>
              </a>
              <a
                className={styles.btn}
                href={"https://werberat.at/show_86.aspx"}
                target="_blank"
              >
                <span>Werberat</span>
                <IconContext.Provider
                  value={{
                    size: "2.5em",
                    color: "#4b596b",
                  }}
                >
                  <CgWebsite />
                </IconContext.Provider>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
interface ModalProps {
  imageNumber: number;
  setImageNumber: any;
}
