import React, { useState } from "react";
import { data } from "../helper/data";
import styles from "./grid.module.css";

export default function Grid({ setImageNumber }: any) {
  const openModal = (imageNumber: number) => {
    setImageNumber(imageNumber);
  };

  return (
    <div className={styles.grid}>
      {data.map((item) => (
        <>
          <img
            key={item.id}
            src={item.img}
            onClick={() => openModal(item.id)}
          />
        </>
      ))}
    </div>
  );
}
