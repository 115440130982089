import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styles from "./imageSlider.module.css";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { IconContext } from "react-icons";
const responsive = {
  tablet: {
    breakpoint: { max: 700, min: 0 },
    items: 2,
  },
  phone: {
    breakpoint: { max: 400, min: 0 },
    items: 1,
  },
};

export default function ImageSlider({ setImageNumber }: any) {
  const openModal = (imageNumber: number) => {
    setImageNumber(imageNumber);
  };
  return (
    <div className={styles.imageSlider}>
      <Carousel
        ssr
        itemClass="image-item"
        responsive={responsive}
        className={styles.carousel}
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
      >
        <img
          src={"img/image1.png"}
          draggable={false}
          onClick={() => openModal(1)}
        />
        <img
          src={"img/image2.png"}
          draggable={false}
          onClick={() => openModal(2)}
        />
        <img
          src={"img/image3.png"}
          draggable={false}
          onClick={() => openModal(3)}
        />
        <img
          src={"img/image4.png"}
          draggable={false}
          onClick={() => openModal(4)}
        />
        <img
          src={"img/image5.png"}
          draggable={false}
          onClick={() => openModal(5)}
        />
      </Carousel>
    </div>
  );
}
const CustomLeftArrow = ({ onClick, ...rest }: any) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType },
  } = rest;
  // onMove means if dragging or swiping in progress.
  return (
    <button onClick={() => onClick()} className={styles.leftButton}>
      <IconContext.Provider
        value={{
          size: "2em",
          color: "#fff",
        }}
      >
        <IoIosArrowBack />
      </IconContext.Provider>
    </button>
  );
};
const CustomRightArrow = ({ onClick, ...rest }: any) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType },
  } = rest;
  // onMove means if dragging or swiping in progress.
  return (
    <button onClick={() => onClick()} className={styles.rightButton}>
      <IconContext.Provider
        value={{
          size: "2em",
          color: "#fff",
        }}
      >
        <IoIosArrowForward />
      </IconContext.Provider>
    </button>
  );
};
